import { Button, Icon, IconButton, Toggle } from '@monash/portal-react';
import { useMemo, useRef, useState } from 'react';
import mapAdvancedStandings from '../../../utilities/data-transform/map-advanced-standings';
import c from './units-and-results.module.scss';
import Year from './year/Year';

const UnitsAndResults = ({
  years,
  priorStudy,
  openGuideModal,
  defaultViewOptionIndex,
  earliestFirst,
}) => {
  const viewOptions = [
    {
      value: 'List View',
      icon: <Icon.LayoutList />,
      ariaLabel: 'View units in list layout',
    },
    {
      value: 'Grid View',
      icon: <Icon.LayoutGrid />,
      ariaLabel: 'View units in grid layout',
    },
  ];

  const [view, setView] = useState(viewOptions[defaultViewOptionIndex]);
  const [earliestToLatest, setEarliestToLatest] = useState(earliestFirst);

  // Unit and results
  const record = useMemo(
    () =>
      priorStudy.length
        ? [
            // Add formatted advanced standings to start of unit results
            mapAdvancedStandings(priorStudy),
            ...Object.values(years),
          ]
        : Object.values(years),
    [years, priorStudy]
  );

  // Sorting
  const sortedRecord = earliestToLatest ? record : [...record].reverse();
  const guideRef = useRef();

  return (
    <section className={c.academicRecord} aria-label="Unit and results">
      <div className={c.headerGroup}>
        <div className={c.header}>
          <h2>Units and results</h2>{' '}
          <IconButton
            ref={guideRef}
            onClick={() => openGuideModal(guideRef)}
            icon={Icon.InfoCircle}
            aria-label="Glossary"
            aria-haspopup="dialog"
          />
        </div>
        <div
          className={c.actionGroup}
          aria-label="Unit and results toolbar"
          role="region"
        >
          <div className={c.action}>
            <IconButton
              onClick={print}
              icon={Icon.Printer}
              aria-label="Print"
            />
          </div>
          <div className={c.action}>
            <Button
              onClick={() => {
                setEarliestToLatest((f) => !f);
              }}
              variant="text"
              iconPosition="right"
              icon={
                earliestToLatest ? (
                  <Icon.ArrowDown size={24} />
                ) : (
                  <Icon.ArrowUp size={24} />
                )
              }
            >
              {earliestToLatest ? 'Earliest first' : 'Latest first'}
            </Button>
          </div>
          <div className={c.action}>
            <Toggle
              ariaLabel="Change view type"
              options={viewOptions}
              selected={view}
              setSelected={setView}
            />
          </div>
        </div>
      </div>

      <div className={c.years}>
        {sortedRecord.map((year) => (
          <Year
            year={year}
            listView={view.value === 'List View'}
            earliestToLatest={earliestToLatest}
            key={year.id}
            openGuide={openGuideModal}
          />
        ))}
      </div>
    </section>
  );
};

export default UnitsAndResults;
