import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-unit-code.module.scss';

const BlockUnitCode = ({ code = '', description = null }) => {
  return (
    <span className={c.blockUnitCode}>
      <VirtuallyHidden element="label" text="Unit code:" />
      <abbr title={description} aria-label={code.split('').join(' ')}>
        {code}
      </abbr>
    </span>
  );
};

export default BlockUnitCode;
