import React, { useContext, useState } from 'react';
import { LoadingIndicator, Tabs, Loading } from '@monash/portal-react';
import { RenderAnimation } from '@monash/portal-frontend-common';
import c from './profile.module.scss';
import About from './about/About';
import backgroundAnimation from '../../../assets/lotties/profile-background-final.json';
import Contact from './contact/Contact';
import Header from './header/Header';
import { StudentContext } from '../../providers/StudentProvider';
import PageContainer from '../../ui/page-container/PageContainer';
import MPass from './m-pass/MPass';

const Profile = () => {
  const { profileData, loadingProfileData } = useContext(StudentContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabs = ['About', 'M-Pass account', 'Contact'];

  return (
    <>
      <PageContainer>
        <Loading
          isLoading={loadingProfileData}
          loadingMsg="Loading profile"
          loadingPage={
            // TODO: refactor and use page container component instead
            // TODO: update loading indicator component and pass message in as a prop
            <div className={c.loadingScreen}>
              <LoadingIndicator />
              <p>Loading profile</p>
            </div>
          }
        >
          {profileData && (
            <div className={c.profile}>
              <div
                className={c.profileContent}
                role="region"
                aria-label="Profile"
              >
                <Header />
                <section
                  className={c.studentInfo}
                  aria-label="Personal information"
                >
                  {/* TODO: update tabs component for alternative alignment option */}
                  <div className={c.tabs}>
                    <Tabs
                      tabs={tabs}
                      selected={selectedTabIndex}
                      onChange={(i) => {
                        setSelectedTabIndex(i);
                      }}
                    />
                  </div>
                  <div
                    className={c.contentContainer}
                    id={`tabpanel-${selectedTabIndex}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${selectedTabIndex}`}
                  >
                    {selectedTabIndex === 0 && <About />}
                    {selectedTabIndex === 1 && <MPass />}
                    {selectedTabIndex === 2 && <Contact />}
                  </div>
                </section>
              </div>
            </div>
          )}
        </Loading>
      </PageContainer>

      <div className={c.background}>
        <RenderAnimation
          className={c.animation}
          lottieFile={backgroundAnimation}
          aspectRatio="xMidYMax"
        />
      </div>
    </>
  );
};

export default Profile;
