import React, { useRef } from 'react';
import { Icon, IconButton } from '@monash/portal-react';
import c from './block-label.module.scss';

const BlockLabel = ({ label, guide = false, openGuide }) => {
  const guideRef = useRef();

  return (
    <div className={`${c.blockLabel} ${c.hiddenInPrint}`}>
      <label aria-hidden="true">{label}</label>
      {guide && (
        <IconButton
          className={c.infoButton}
          ref={guideRef}
          onClick={() => openGuide(guideRef)}
          variant="text"
          iconPosition="right"
          icon={Icon.InfoCircle}
          size={20}
          aria-label="Glossary"
          aria-haspopup="dialog"
        />
      )}
    </div>
  );
};

export default BlockLabel;
