import { Icon, VirtuallyHidden } from '@monash/portal-react';
import c from './avatar.module.scss';

const Avatar = ({ disabled, avatar, i, selected, setSelectedAvatar }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => setSelectedAvatar(avatar)}
      className={`${c.avatar} ${selected ? c.selected : ''}`}
      key={i}
    >
      {selected && (
        <div className={c.selectedIndicator}>
          <Icon.Check size={24} color="var(--card-cta-text-color)" />
        </div>
      )}
      <div className={c.imageContainer}>
        <img
          src={avatar?.url || `data:image/jpeg;base64,${avatar?.image}`}
          alt={avatar?.description || 'id photo'}
        />
        {selected ? <VirtuallyHidden text="(Selected)" /> : null}
      </div>
    </button>
  );
};

export default Avatar;
