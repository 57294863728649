import React from 'react';
import c from './encumbrance.module.scss';
import { ExternalLink } from '@monash/portal-react';
import { EXTERNAL_LINKS } from '@monash/portal-frontend-common';
import encumbranceImageUrl from '../../../../assets/images/encumbrance.svg';

const Encumbrance = () => {
  return (
    <section className={c.encumbrance} aria-label="Encumbrance">
      <h2>You have an encumbrance</h2>
      <p>
        It looks like there’s a block on your account (an encumbrance) so you
        won’t be able to see your results right now.{' '}
      </p>
      <p>
        Check your Monash email for all the details (including how to remove the
        block). If you’re enrolled at an Australian campus, you can use our
        encumbrance check in the virtual assistant to find out what you need to
        do.
      </p>
      <ExternalLink
        variant="text"
        link={EXTERNAL_LINKS.ENCUMBRANCE}
        text="Encumbrance check"
      />
      <img src={encumbranceImageUrl} alt="" />
    </section>
  );
};

export default Encumbrance;
