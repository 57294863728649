import React from 'react';
import c from './guide.module.scss';
import creditGrantingStatus from '../../../../utilities/data/credit-granting-status-props.json';
import unitStatus from '../../../../utilities/data/unit-status-props.json';
import BlockStatusTag from '../block-components/block-status-tag/BlockStatusTag';
import { swapAnchorForExternalLink } from '@monash/portal-frontend-common';

const flattenedStatusList = [
  ...Object.values(unitStatus),
  ...Object.values(creditGrantingStatus).reduce(
    (prev, curr) => [...prev, ...Object.values(curr)],
    []
  ),
];

const Guide = () => {
  return (
    <div className={c.guide}>
      {flattenedStatusList.map((status) => (
        <div className={c.status} key={status.label}>
          <BlockStatusTag status={status} />
          <p className={c.statusContent}>
            {swapAnchorForExternalLink(status.description)}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Guide;
