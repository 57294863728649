import React, { useContext, useRef, useState, useEffect } from 'react';
import c from './course-plan.module.scss';
import {
  Button,
  Icon,
  Link,
  Loading,
  LoadingIndicator,
  Modal,
  ModalSection,
  RouterState,
} from '@monash/portal-react';
import Guide from '../course-progress/units-and-results/guide/Guide';
import {
  MenuContext,
  fsSetDoc,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import UnitsAndResults from '../course-progress/units-and-results/UnitsAndResults';
import PageContainer from '../../ui/page-container/PageContainer';
import SendUnitsModal from './send-units-modal/SendUnitsModal';
import Section from '../../ui/section/Section';
import { decompress } from '../course-planning/compression';
import { courseProgressify } from '../course-planning/utils';
import { StudentContext } from '../../providers/StudentProvider';

const CoursePlan = () => {
  const { size } = useContext(MenuContext);
  const { route, redirect } = useContext(RouterState);
  const { enrolments } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const [coursePlan, setCoursePlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSendingUnits, setIsSendingUnits] = useState(false);
  const [haveUnitsBeenSent, setHaveUnitsBeenSent] = useState(false);
  const [editUrlData, setEditUrlData] = useState(null);
  const [refData, setRefData] = useState(null);
  const ctaButtonSize = size === 'S' ? 'small' : 'medium';

  const ecBaseUrl =
    process.env.REACT_APP_ENV === 'prod' ? 'enrol' : 'enrol-qat';

  useEffect(() => {
    fetch(
      `https://storage.googleapis.com/monash-portal-prod-storage/small-data.json`
    )
      .then((r) => r.json())
      .then((data) => setRefData(data));
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const urlData = query.get('d');

    if (route.data) {
      setCoursePlan(route.data);
      setLoading(false);
      setEditUrlData(route.data.url);
    } else if (urlData && enrolments && refData) {
      const decompressed = decompress(urlData);
      const enrolled = enrolments?.filter(
        (e) => e.status === 'ENROLLED' && e.code === 'B2000'
      )[0];
      const courseProgressed = courseProgressify(
        decompressed,
        enrolled,
        refData
      );
      setCoursePlan(courseProgressed);
      setLoading(false);
      setEditUrlData(urlData);

      // save to fs
      const saving = {
        ...courseProgressed,
        url: urlData,
      };
      fsSetDoc(
        `EC-plans/${currentUser.uid}`,
        JSON.parse(JSON.stringify(saving))
      );
    }
  }, [enrolments, refData]);

  // Guide modal
  const modalTitle = 'Glossary';
  const [modal, setModal] = useState(false);
  const lastOpenedGuideRef = useRef();
  const openGuideModal = (ref) => {
    setModal(true);
    lastOpenedGuideRef.current = ref.current;
  };
  const closeGuideModal = () => {
    setModal(false);
    lastOpenedGuideRef.current.focus();
  };

  if (!loading && !coursePlan) redirect('/page-not-found');

  return (
    <div className={c.coursePlan}>
      <Loading
        isLoading={loading}
        loadingPage={
          <div className={c.loadingScreen}>
            <LoadingIndicator />
            <p>Loading course plan</p>
          </div>
        }
      >
        <SendUnitsModal
          coursePlan={coursePlan}
          open={isSendingUnits}
          setOpen={setIsSendingUnits}
          setHaveUnitsBeenSent={setHaveUnitsBeenSent}
        />

        <PageContainer>
          <div className={c.navigation}>
            <Link to="/course-planning-beta" tabIndex="-1">
              <Button size="small" variant="text" icon={<Icon.ChevronLeft />}>
                Back
              </Button>
            </Link>
          </div>
          <Modal
            open={modal}
            onClose={closeGuideModal}
            size={size}
            ariaLabel={modalTitle}
            ariaDescribedby={null}
          >
            <ModalSection
              title={modalTitle}
              titleTabIndex={null}
              ariaLabelledby="glossaryModalTitle"
              ariaDescribedby="glossaryModalContent"
            >
              <Guide />
            </ModalSection>
          </Modal>
          <Section title="Course plan" titleAlignCenter={true}>
            <div className={c.planHeader}>
              <p>{`${coursePlan?.code} \u2013 ${coursePlan?.title}`}</p>
              <div className={c.actions}>
                {/* TODO: update external link options then update button to external link */}
                <Button
                  size={ctaButtonSize}
                  variant="secondary"
                  iconPosition="right"
                  icon={<Icon.External size={20} />}
                  onClick={() =>
                    editUrlData &&
                    window.open(
                      `https://${ecBaseUrl}.apps.monash.edu/?d=${editUrlData}`
                    )
                  }
                >
                  Edit Path
                </Button>{' '}
                {/* TODO: update button to external link */}
                {haveUnitsBeenSent ? (
                  <Button
                    size={ctaButtonSize}
                    variant="primary"
                    iconPosition="right"
                    icon={<Icon.External size={20} />}
                    onClick={() =>
                      window.open('https://my.monash.edu/wes/student_services/')
                    }
                  >
                    Go to WES
                  </Button>
                ) : (
                  <Button
                    size={ctaButtonSize}
                    variant="primary"
                    iconPosition="right"
                    mode="canvas"
                    onClick={() => setIsSendingUnits(true)}
                    icon={<Icon.Rocket size={20} />}
                    disabled={!coursePlan?.years['2024']}
                  >
                    Send 2024 units to WES
                  </Button>
                )}
              </div>
            </div>
          </Section>
          <UnitsAndResults
            years={coursePlan?.years}
            priorStudy={coursePlan?.advancedStandings}
            openGuideModal={openGuideModal}
            defaultViewOptionIndex={1}
            earliestFirst={false}
          />
          <div className={c.printOnlyGlossary}>
            <Section title="Glossary">
              <Guide />
            </Section>
          </div>
        </PageContainer>
      </Loading>
    </div>
  );
};

export default CoursePlan;
