import { useContext } from 'react';
import {
  capitaliseFirstWord,
  MenuContext,
} from '@monash/portal-frontend-common';
import Card from '../../../../ui/card/Card';
import IntermissionBlock from '../blocks/intermission-block/IntermissionBlock';
import GridBlock from '../blocks/grid-block/GridBlock';
import ListBlock from '../blocks/list-block/ListBlock';
import ListHeaderBlock from '../blocks/list-block/ListHeaderBlock';
import c from './period.module.scss';

const Period = ({ period, listView, openGuide }) => {
  const { size } = useContext(MenuContext);
  const displayInSingleColumn = listView || period.name === 'Intermission';

  const renderBlocks = () => {
    const blocks = Object.values(period.blocks);

    if (period.name === 'Intermission') {
      return blocks.map((block) => (
        <IntermissionBlock block={block} key={block.id} />
      ));
    }
    if (listView) {
      return (
        <Card>
          <ul className={c.listedBlocks}>
            <ListHeaderBlock openGuide={openGuide} />
            {blocks.map((block, i) => (
              <ListBlock key={block.id} block={block} openGuide={openGuide} />
            ))}
          </ul>
        </Card>
      );
    } else {
      return blocks.map((block, i) => (
        <GridBlock block={block} key={block.id} />
      ));
    }
  };

  return (
    <li className={c.period}>
      {period.name && <h4>{capitaliseFirstWord(period.name)}</h4>}
      <ul
        className={`${c.blocks} ${!displayInSingleColumn ? c.gridDisplay : ''}`}
        style={{
          gridTemplateColumns: `repeat(${
            displayInSingleColumn ? 1 : size === 'S' ? 2 : 4
          }, 1fr)`,
        }}
      >
        {renderBlocks()}
      </ul>
    </li>
  );
};

export default Period;
