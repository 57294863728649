import React from 'react';
import c from './planning.module.scss';
import {
  IllustrationLoadingCrane,
  IllustrationLoadingLadder,
  IllustrationLoadingPerson1Bottom,
  IllustrationLoadingPerson1Top,
  IllustrationLoadingPerson2,
  IllustrationLoadingPerson2Arm,
  IllustrationLoadingPlan,
} from '@monash/portal-react';

const Planning = () => {
  return (
    <div className={c.plan}>
      <div className={c.crane}>
        <IllustrationLoadingCrane />
      </div>
      <div className={c.string} />
      <div className={c.unit1} />
      <div className={c.plan}>
        <IllustrationLoadingPlan />
      </div>
      <div className={c.ladder}>
        <IllustrationLoadingLadder />
      </div>
      <div className={c.unit2} />
      <div className={c.person1Top}>
        <IllustrationLoadingPerson1Top />
      </div>
      <div className={c.person1Bottom}>
        <IllustrationLoadingPerson1Bottom />
      </div>
      <div className={c.shadow} />

      <div className={c.unit3}>
        <div className={c.person2}>
          <IllustrationLoadingPerson2 />
          <div className={c.person2Arm}>
            <IllustrationLoadingPerson2Arm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planning;
