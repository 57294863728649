import {
  APIContext,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import { Alert, Loading } from '@monash/portal-react';
import { useContext, useEffect, useState } from 'react';
import { StudentContext } from '../../../providers/StudentProvider';
import c from './avatar-selector.module.scss';
import Avatar from './avatar/Avatar';

const AvatarSelector = ({
  setSelectedAvatar,
  selectedAvatar,
  isUpdating,
  setIsAvatarOptionsLoaded,
}) => {
  const { getAvatarOptions } = useContext(APIContext);
  const { mixAvatar } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);

  const [isLoading, setIsLoading] = useState(true);
  const [avatarOptions, setAvatarOptions] = useState(null);
  const [avatarOptionsError, setAvatarOptionsError] = useState(null);

  // get avatar options
  useEffect(() => {
    setIsLoading(true);
    setAvatarOptionsError(null);
    if (currentUser) {
      getAvatarOptions()
        .then((r) => {
          setAvatarOptions(r);
          setIsLoading(false);
          setIsAvatarOptionsLoaded(true);
        })
        .catch((error) => {
          setAvatarOptionsError(error);
          setIsLoading(false);
        });
    }
  }, [currentUser, setIsAvatarOptionsLoaded]);

  return (
    <div className={c.avatarSelector}>
      <Loading isLoading={isLoading}>
        <div className={c.avatarOptions}>
          {mixAvatar && (
            <Avatar
              disabled={isUpdating}
              avatar={mixAvatar}
              setSelectedAvatar={setSelectedAvatar}
              selected={mixAvatar?.id === selectedAvatar?.id}
            />
          )}
          {avatarOptions?.map((avatarOption, i) => (
            <Avatar
              key={avatarOption.description}
              disabled={isUpdating}
              avatar={avatarOption}
              setSelectedAvatar={setSelectedAvatar}
              selected={avatarOption?.toyId === selectedAvatar?.toyId}
            />
          ))}
        </div>
        {avatarOptionsError && (
          <Alert type="error">
            Sorry, we are having some <strong>issues retrieving avatars</strong>
            , please try again later.
          </Alert>
        )}
      </Loading>
    </div>
  );
};

export default AvatarSelector;
