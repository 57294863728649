import React from 'react';
import { VirtuallyHidden } from '@monash/portal-react';
import { gradeScreenReaderName } from '../../../../course-planning/grades';
import c from './block-grade.module.scss';

const BlockGrade = ({ mark, grade }) => {
  const gradeSRName = gradeScreenReaderName(grade);
  const markDisplayString = grade ? `(${mark})` : mark;

  return (
    <div className={c.blockGrade}>
      <VirtuallyHidden element="label" text="Unit grade:" />
      <span aria-hidden={true}>{grade}</span> {mark ? markDisplayString : null}
      <VirtuallyHidden text={`(${gradeSRName})`} />
    </div>
  );
};

export default BlockGrade;
