import { fsGetDoc } from '@monash/portal-frontend-common';
import { useState, useEffect } from 'react';

export const useFeatureToggle = () => {
  const [features, setFeatures] = useState({});

  useEffect(() => {
    const loadFeatures = async () => {
      const snap = await fsGetDoc('config/features');
      if (snap?.exists()) {
        const data = snap.data();
        setFeatures(data);
      }
    };
    loadFeatures();
  }, []);

  return features;
};
