import React, { useState } from 'react';
import c from './emergency-contact.module.scss';
import { capitaliseAllWords } from '@monash/portal-frontend-common';
import { Button } from '@monash/portal-react';
import { transformAddress } from '../utils';

const EmergencyContact = ({ data }) => {
  const [detailsShown, setDetailsShown] = useState(false);

  const contactNames = data
    .map((contact) => capitaliseAllWords(contact.preferredName))
    .join(', ');

  return (
    <div className={c.emergencyContact}>
      <div className={c.contacts}>
        <p>{contactNames}</p>
        <Button
          variant="text"
          size="medium"
          onClick={() => setDetailsShown((f) => !f)}
        >
          {detailsShown ? 'Hide details' : 'View details'}
        </Button>
      </div>
      {detailsShown
        ? data.map((contact) => (
            <div className={c.contactCard} key={contact.preferredName}>
              {contact.preferredName && (
                <div className={c.item}>
                  <label>Name</label>
                  <p>{capitaliseAllWords(contact.preferredName)}</p>
                </div>
              )}

              {contact.additionalDetails.relationship && (
                <div className={c.item}>
                  <label>Relationship</label>
                  <p>
                    {capitaliseAllWords(contact.additionalDetails.relationship)}
                  </p>
                </div>
              )}

              {contact.phone.map((phone) => (
                <div className={c.itemBig} key={phone.phoneNumber}>
                  <label>Phone number</label>
                  <p>{capitaliseAllWords(phone.phoneNumber)}</p>
                </div>
              ))}

              {contact.address.map((address) => (
                <div className={c.itemBig} key={address.addressLine1}>
                  <label>Address</label>
                  <p>{transformAddress(address) || '-'}</p>
                </div>
              ))}
            </div>
          ))
        : null}
    </div>
  );
};

export default EmergencyContact;
