import { capitaliseAllWords } from '@monash/portal-frontend-common';

export const transformAddress = (address) => {
  if (!address) return null;

  const addressLines = [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.addressLine4,
  ];

  const combineStateAndPostCode = (state, postCode) => {
    if (!postCode && !state) return;
    return [state, postCode].filter((v) => v).join(' ');
  };

  const capitaliseItems = (array) =>
    array.map((item) => capitaliseAllWords(item));

  let joined;

  switch (address.type) {
    case 'Emergency':
      joined = [
        ...capitaliseItems([...addressLines, address.city]),
        combineStateAndPostCode(address.country, address.postalCode),
      ];
      break;
    default:
      joined = [
        ...capitaliseItems([...addressLines, address.suburb]),
        combineStateAndPostCode(address.state, address.postCode),
      ];
  }
  return joined.filter((v) => v).join(', ');
};
