import { useContext } from 'react';
import { MenuContext } from '@monash/portal-frontend-common';
import BlockLabel from '../../block-components/block-label/BlockLabel';
import c from './list-block.module.scss';

const ListHeaderBlock = ({ openGuide }) => {
  const { size } = useContext(MenuContext);
  const shouldDisplay = size === 'L'; // display on desktop BP
  const items = [
    {
      label: 'Unit code',
    },
    {
      label: 'Unit name',
    },
    {
      label: 'Status',
      guide: true,
    },
    {
      label: 'Grade',
    },
    {
      label: 'Credit points',
    },
  ];

  return shouldDisplay ? (
    <div className={`${c.listBlock} ${c[size]}`} data-block="header">
      {items.map((item) => {
        return (
          <div
            className={c.item}
            key={item.label}
            // Using item labels for semantic grid styles
            // TODO: explore other ways that do not depend on label text
            style={{ gridArea: item.label.replace(/\s/g, '') }}
          >
            <BlockLabel
              label={item.label}
              guide={item.guide || false}
              openGuide={openGuide}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default ListHeaderBlock;
