import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Router } from '@monash/portal-react';
import {
  MegaWrapper,
  AuthProvider,
  ImpersonationWrapper,
  APIProvider,
  MenuProvider,
  accessControl,
  LoadingScreen,
  FeatureProvider,
  AccessibilityProvider,
  NotificationProvider,
} from '@monash/portal-frontend-common';
import { FEATURES, FEATURE_FLAGS } from './constants/features';
import StudentProvider from './components/providers/StudentProvider';
import SnackbarProvider from './components/providers/SnackbarProvider';
import homeLoading from './assets/lotties/home-loading.json';
import App from './App';
import './index.css';
import initMonitoring from './initMonitoring';

const env = process.env.REACT_APP_ENV;
if (process.env.REACT_APP_ENV !== 'local') {
  initMonitoring(env);
}

const container = document.getElementById('root');
const root = createRoot(container);

const loadingScreen = <LoadingScreen animation={homeLoading} />;
const wrappers = [
  {
    comp: AuthProvider,
    props: {
      nolanding: true,
      loadingPage: loadingScreen,
      accessControl: accessControl.isStudent,
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : sessionStorage.getItem('fakeData'),
    },
  },
  React.StrictMode,
  {
    comp: APIProvider,
    props: {
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : sessionStorage.getItem('fakeData'),
    },
  },
  {
    comp: FeatureProvider,
    props: {
      defaultFeatures: FEATURE_FLAGS,
    },
  },
  ImpersonationWrapper,
  StudentProvider,
  MenuProvider,
  Router,
  AccessibilityProvider,
  SnackbarProvider,
  NotificationProvider,
];

root.render(
  <MegaWrapper providers={wrappers}>
    <App />
  </MegaWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
