import {
  Button,
  Modal,
  Alert,
  ModalAction,
  ModalSection,
  useMedia,
  ExternalLink,
} from '@monash/portal-react';
import { APIContext } from '@monash/portal-frontend-common';
import React, { useContext, useEffect, useRef, useState } from 'react';
import c from './send-units-modal.module.scss';
import Year from '../../course-progress/units-and-results/year/Year';
import congratsImageUrl from '../../../../assets/images/congrats.svg';

const confetti = require('canvas-confetti');

const SendUnitsModal = ({
  coursePlan,
  setHaveUnitsBeenSent,
  open,
  setOpen,
}) => {
  const { updateUnitEnrolments } = useContext(APIContext);
  const size = useMedia();
  const [isSendingUnits, setIsSendingUnits] = useState(false);
  const [sendUnitsSuccess, setSendUnitsSuccess] = useState(false);
  const [sendUnitsError, setSendUnitsError] = useState(
    coursePlan.years['2024']?.periods.length === 0
  );
  const ref = useRef();
  const modalTitle = 'Send planned units in 2024 to WES';
  const ctaButtonSize = size === 'S' ? 'small' : 'medium';

  const sendUnits = () => {
    setIsSendingUnits(true);
    if (coursePlan.years['2024']?.periods.length > 0) {
      const units = coursePlan.years['2024'].periods
        .reduce(
          (prev, curr) => [...prev, ...curr.blocks.map((b) => b.unitEnrolment)],
          []
        )
        .filter((x) => x);

      updateUnitEnrolments('B2000', units)
        .then((r) => handleSendSuccess())
        .catch((e) => setSendUnitsError(true));
    } else {
      setSendUnitsError(true);
    }
  };

  const handleSendSuccess = () => {
    setIsSendingUnits(false);
    setSendUnitsError(false);
    setSendUnitsSuccess(true);
    setHaveUnitsBeenSent(true);
  };

  // Modal
  const closeModal = () => setOpen(false);

  useEffect(() => {
    if (sendUnitsSuccess) {
      const canvas = ref.current;
      canvas.confetti =
        canvas.confetti || confetti.create(canvas, { resize: true });
      setTimeout(() => {
        canvas.confetti({
          particleCount: 100,
          spread: 100,
          origin: { y: 0.6 },
          colors: ['#A1B6CA', '#E8EEF4', '#FF9800'],
        });
      }, 200);
    }
  }, [sendUnitsSuccess]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      size={size}
      ariaLabel={modalTitle}
      ariaDescribedby={null}
    >
      <ModalSection
        title={modalTitle}
        titleTabIndex={null}
        ariaLabelledby="sendUnitsModalTitle"
        ariaDescribedby="sendUnitsModalContent"
      >
        {!sendUnitsSuccess && (
          <div className={c.units}>
            <Year
              year={coursePlan.years['2024']}
              listView={false}
              earliestToLatest={true}
              sticky={false}
            />
          </div>
        )}
        {!isSendingUnits && sendUnitsSuccess && (
          <div className={c.success}>
            <img src={congratsImageUrl} alt="" />
            <canvas ref={ref} />
            <h2>All units have been sent to WES successfully!</h2>
            <p>
              Those units have been added to your enrolment as unconfirmed
              units.
              <strong>You will need to go to WES </strong> to{' '}
              <strong>confirm and enrol</strong> in the units.
            </p>
          </div>
        )}
      </ModalSection>
      <ModalAction position="center">
        {!sendUnitsSuccess && (
          <div className={c.modalAction}>
            {sendUnitsError ? (
              <Alert type="error">
                Sorry, we're currently experiencing some{' '}
                <strong>difficulties in sending your units to WES</strong> –
                please try again later.
              </Alert>
            ) : null}
            <div className={c.modalButtons}>
              <Button variant="text" size={ctaButtonSize} onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size={ctaButtonSize}
                onClick={sendUnits}
                loading={isSendingUnits}
                loadingMessage="Sending units"
                disabled={isSendingUnits}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
        {!isSendingUnits && sendUnitsSuccess && (
          <div className={c.modalAction}>
            <div className={c.modalButtons}>
              <Button variant="text" size={ctaButtonSize} onClick={closeModal}>
                Do it later
              </Button>
              <ExternalLink
                variant="primary"
                link="https://my.monash.edu/wes/student_services/"
                text="Go to WES"
              />
            </div>
          </div>
        )}
      </ModalAction>
    </Modal>
  );
};

export default SendUnitsModal;
