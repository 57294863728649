import React, { useState } from 'react';
import { Button, Alert } from '@monash/portal-react';
import c from './info-item.module.scss';

const InfoItem = ({
  icon: Icon,
  form: Form,
  name,
  data,
  component,
  error,
  action,
}) => {
  const [inEdit, setInEdit] = useState(false);

  return (
    <div className={c.infoItem}>
      <Icon />
      {!inEdit && (
        <div className={c.content}>
          <label>{name}</label>
          {!error ? (
            component || <p data-sentry-mask>{data}</p>
          ) : (
            <Alert>Currently unavailable</Alert>
          )}
        </div>
      )}
      {Form && !error && !inEdit && (
        <Button
          variant="text"
          size="small"
          onClick={() => setInEdit(true)}
          aria-label="Edit Pronouns"
        >
          Edit
        </Button>
      )}
      {Form && !error && inEdit && (
        <Form inEdit={inEdit} setInEdit={setInEdit} />
      )}

      {!error && action}
    </div>
  );
};

export default InfoItem;
