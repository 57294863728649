import { MenuContext } from '@monash/portal-frontend-common';
import React, { useContext } from 'react';
import c from './interval-markers.module.scss';

const IntervalMarkers = ({ barMaxCP }) => {
  const { size } = useContext(MenuContext);

  // credit points intervals
  const interval = 6;
  const labelledInterval = size === 'S' ? 48 : 24;

  // interval markers
  const markers = [];
  for (let i = 0; i <= barMaxCP / interval; i++) {
    markers.push({
      CP: i * interval,
      labelled:
        (i * interval) % labelledInterval === 0 || i === barMaxCP / interval,
    });
  }

  // reposition the last label when the last two labels have a small difference in CP
  const secondLastLabelledInterval = [...markers]
    .reverse()
    .find((marker) => marker.CP !== barMaxCP && marker.labelled);
  const repositionLastLabelledInterval =
    barMaxCP % labelledInterval !== 0 &&
    barMaxCP - secondLastLabelledInterval?.CP <= labelledInterval / 2;

  return (
    <div className={c.intervalMarkers}>
      {markers.map((marker, i) => (
        <div
          key={marker.CP}
          className={`${c.marker} ${marker.labelled && c.labelled}`}
          style={{
            left: `calc(${(100 / (barMaxCP / interval)) * i}%)`,
          }}
        >
          {marker.labelled && (
            <label
              className={`${
                marker === markers[markers.length - 1] &&
                repositionLastLabelledInterval &&
                c.up
              }`}
            >
              {marker.CP}
            </label>
          )}
        </div>
      ))}
    </div>
  );
};

export default IntervalMarkers;
